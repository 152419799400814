import {
  json,
  type MetaFunction,
  type LinksFunction,
  type LoaderFunctionArgs,
} from "@remix-run/node"
import {
  Link,
  Links,
  Meta,
  NavLink,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,
  useMatches,
  isRouteErrorResponse,
} from "@remix-run/react"
import { useEffect, useRef } from "react"
import { MenuProvider } from "./contexts/MenuContext"
import { MenuWrapper } from "./components/MenuWrapper"
import { Header } from "./components/Header"
import { Footer } from "./components/Footer"
import { metadataMapper } from "./utils/metaMapper"

import styles from "./styles/app.css?url"
import { withSentry } from "@sentry/remix"

interface Handle {
  bodyId?: string
}

export const meta: MetaFunction = () => {
  const metadata = metadataMapper()
  return metadata
}

export const links: LinksFunction = () => [
  { rel: "shortcut icon", type: "text/html", href: "/favicon.ico" },
  {
    rel: "image_src",
    type: "image/jpeg",
    href: "https://renewingyourmind.org/images/social.jpg",
  },
  {
    rel: "alternate",
    type: "application/rss+xml",
    title: "Renewing Your Mind with R.C. Sproul",
    href: "https://renewingyourmind.ligonier.org/rss",
  },
  {
    rel: "stylesheet",
    href: "https://cloud.typography.com/6693092/7444552/css/fonts.css",
    media: "print",
  },
  { rel: "stylesheet", href: styles },
]

export async function loader() {
  return json({ GTM_CONTAINER_ID: process.env.GTM_CONTAINER_ID })
}

function App() {
  const location = useLocation()
  const highlightElement = useRef<Element>()
  const highlightTimeoutId = useRef<ReturnType<typeof setTimeout>>()
  const { GTM_CONTAINER_ID } = useLoaderData<typeof loader>()

  useEffect(() => {
    // If the url contains a # hash, scroll the element with that id into view
    // Also highlight the element for a short time so the user can see what was focused
    if (location.hash) {
      if (highlightTimeoutId.current) {
        clearTimeout(highlightTimeoutId.current)
        highlightTimeoutId.current = undefined
      }

      if (highlightElement.current) {
        highlightElement.current.classList.remove("highlight")
      }

      const el = document.getElementById(location.hash.slice(1))

      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "start" })
        el.classList.add("highlight")
        highlightElement.current = el
        highlightTimeoutId.current = setTimeout(() => {
          el.classList.remove("highlight")
        }, 2000)
      }
    }
  }, [location])

  // set bottom-padding to body based on current height of footer
  useEffect(() => {
    const body = document.body
    const footer = document.getElementById("footer-pane")

    const resize = function () {
      body.style.paddingBottom = footer?.clientHeight + "px"
    }

    if (typeof window !== "undefined") {
      resize() // When the comp. first mounts

      const handleResize = () => {
        resize() // and then every subsequent resize
      }

      addEventListener("resize", handleResize)

      // Cleanup function
      return () => {
        removeEventListener("resize", handleResize)
      }
    }
  }, [])

  useEffect(() => {
    const gtmScript = document.createElement("script")
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${GTM_CONTAINER_ID}');
      `

    document.head.appendChild(gtmScript)

    return () => {
      document.head.removeChild(gtmScript)
    }
  }, [location, GTM_CONTAINER_ID])

  const matches = useMatches()
  const bodyId = matches.length
    ? (matches[matches.length - 1].handle as Handle)?.bodyId
    : "sub"

  return (
    <MenuProvider>
      <html lang="en">
        <head>
          <Meta />
          <Links />
        </head>
        <body id={bodyId}>
          {/* GOOGLE TAG MANAGER NOSCRIPT */}
          <noscript>
            <iframe
              title="gtm"
              src={`https://www.googletagmanager.com/ns.html?id=${GTM_CONTAINER_ID}`}
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            />
          </noscript>
          <MenuWrapper>
            <Header currentPage={location.pathname} />
            <Outlet />
          </MenuWrapper>
          <Footer currentPage={location.pathname} />
          <ScrollRestoration />
          <Scripts />
        </body>
      </html>
    </MenuProvider>
  )
}

export default withSentry(App)

// This type has come from remix run router.
// Without this, the error below was of a type unknown.
type ErrorResponse = {
  status: number
  statusText: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  error?: Error
  internal: boolean
  message?: string
}

export function ErrorBoundary() {
  const error = useRouteError() as ErrorResponse
  const location = useLocation()
  const status = isRouteErrorResponse(error) ? error.status : null
  const message = status ? error?.data : error.message

  return (
    <html lang="en" style={{ height: "100%" }}>
      <head>
        <title>Something Went Wrong</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div id="status-holder">
          <div id="status">
            <Link to="/" id="mark">
              Renewing Your Mind
            </Link>
            <h1>We Apologize</h1>
            <p>{message}</p>
            <nav>
              {status && status === 404 ? (
                <NavLink to="/">Homepage</NavLink>
              ) : (
                <NavLink to={location?.pathname || "/"}>Refresh</NavLink>
              )}
              | <Link to="mailto:feedback@renewingyourmind.org">Support</Link>
            </nav>
          </div>
        </div>
      </body>
    </html>
  )
}
